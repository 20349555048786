<script lang="ts" setup>
import Lang from '@/components/lang'
import { useTgCustomer } from '@/hooks/useTgCustomer'
import { useSwapShow } from '~/composables/app/useSwapShow'

const { enableExchange } = useSwapShow()
const tgLink = useTgCustomer()
const visible = ref(false)
const { config } = useAppState()
</script>

<template>
  <footer class="footer">
    <div class="main">
      <div class="business-div">
        <h3 class="title">{{ $t("mSq24vll6ZpyEhQgy_kDF") }}</h3>
        <ul>
          <li><NuxtLink :to="localePath('/c2c')" data-test-id="eNKF">{{ $t("yiSrOfcmDFQ4S_64vwKmE") }}</NuxtLink></li>
          <li><NuxtLinkLocale to="/b2c" data-test-id="3hNS">{{ $t("FPBi_zuJs4DOUFStIbwoa") }}</NuxtLinkLocale></li>
          <li><NuxtLink :to="localePath('/batch-renting')" data-test-id="g5Ip">{{ $t("WeS47BKEK1K-RnK7o6Yxg") }}</NuxtLink></li>
           <li v-if="enableExchange"><NuxtLink :to="localePath('/swap')" data-test-id="hjO8">{{ $t("IAP3YhQBnAdoNx4lMueLD") }}</NuxtLink></li>
          <li><a :href="`/console/buyer/monitoring-address?lang=${$t('console-lang')}`" data-test-id="krXj">{{ $t("6xzB1kAuo1_WwZWTYqJL3") }}</a></li>
          <li>
            <i v-if="!$bp.atMobiles" class="i-local:tg-s4 icon" />
            <NuxtLink to="https://t.me/feeeIoBot" target="blank" data-test-id="BN9R"><span :class="$bp.atMobiles ? 'blue' : '' ">{{ $t("bvJPJsNJS078AsP3ns8Ac") }}</span></NuxtLink>
          </li>
        </ul>
      </div>
      <div class="support-div">
        <h3 class="title">{{ $t("cgZZNGrxQ4Os2jitEOdAA") }}</h3>
        <ul>
          <li><a :href="`/doc/${$t('doc-lang')}`" data-test-id="ouFP">{{ $t('3p7uB1VlYHERPyaVAlCT7') }}</a></li>
          <li><a :href="`/${currentLocale}/#faq`" data-test-id="7sKo">{{ $t("oLZmwp9WOaEnVJZLKlB0p") }}</a></li>
          <!-- <li><NuxtLink :to="localePath('/announcement')">{{ $t("dTKmOOljwW8ju5qOEXBtv") }}</NuxtLink></li> -->
          <li v-if="$bp.atMobiles">
            <span data-test-id="5xbn" @click="visible = true">{{ $t("V5B8ddj2Wwt4PwMOLeEnj") }}</span>
          </li>
        </ul>
      </div>
      <div class="user-div">
        <h3 class="title">{{ $t("_IL6-3W2GXgfC7sV8-Gbg") }}</h3>
        <ul>
          <li><a :href="`/console?lang=${$t('console-lang')}`" data-test-id="CBPS">{{ $t("vTL9tlsWzbAAgQHYbEYMq") }}</a></li>
          <li>
            <NuxtLink to="#" data-test-id="8ynv" @click="globalModal.Recommend?.open()">{{ $t("s6hGj36jqP0Qyb57_CW9M") }}</NuxtLink>
          </li>
        </ul>
      </div>
      <div class="contact-div">
        <h3 class="title">{{ $t("V5B8ddj2Wwt4PwMOLeEnj") }}</h3>
        <ul>
          <li>
            <span><i class="i-local:tg-s4 icon" />{{ $t("-gpqytKeCEd_i6GCpio91") }}：</span>
            <NuxtLink :to="tgLink" target="_blank">@trongascom</NuxtLink>
          </li>
          <li>
          <NuxtLink to="https://t.me/tronenergymarkets" target="_blank">
            <span><i class="i-local:tg-s4 icon" />{{ $t("P5vQ5M3DaK_vUetztkJKS") }}</span>
          </NuxtLink>
          </li>
          <li>
          <NuxtLink to="https://x.com/Feeeio" target="_blank">
            <span><i class="i-local:twtter icon" />{{ $t("P5vQ5M3DaK_vUetztkJ11") }}@Feeeio</span>
          </NuxtLink>
          </li>
          <li>
            <span>{{ $t("w8omC-wr8B3VlUochWVxP") }}：</span>
            <NuxtLink to="mailto:service@feee.io" target="_blank">service@feee.io</NuxtLink>
          </li>
        </ul>
      </div>
      <div class="other-div">
        <div class="brand" />
        <ClientOnly>
          <Lang class="lang" styleName="s1" />
        </ClientOnly>
      </div>
      <div class="copyright-div">
        <NuxtLink class="text-[--highlight] mr-[14px]" :to="localePath('/')">Feee.io</NuxtLink>
        <span>COPYRIGHT &copy;2022/23</span>
      </div>
    </div>

    <!-- 联系 -->
    <AModal
      v-model:open="visible"
      :footer="null"
      :width="360"
      destroyOnClose
      :closable="true"
      :maskClosable="true"
      wrapClassName="ant-cover__basic-modal"
      :title="$t('V5B8ddj2Wwt4PwMOLeEnj')"
    >
      <div class="flex justify-center items-center flex-col gap-[20px]">
        <img src="~/assets/imgs/connect.png" class="w-[144px] h-[96px] mt-[32px]">
        <ul class="w-[100%] leading-[32px] font-medium">
          <li>
            <span><i class="i-local:tg-s4 text-[18px] mr-[10px]" />{{ $t("-gpqytKeCEd_i6GCpio91") }}：<NuxtLink :to="tgLink" target="_blank" class="decoration-underline">@trongascom</NuxtLink></span>

          </li>
          <li>
            <NuxtLink to="https://t.me/tronenergymarkets" target="_blank" class="decoration-underline">
              <span><i class="i-local:tg-s4 text-[18px] mr-[10px]" />{{ $t("P5vQ5M3DaK_vUetztkJKS") }}</span>
            </NuxtLink>
          </li>
          <li>
            <span><i class="i-local:tg-s5 text-[18px] mr-[10px]" />{{ $t("w8omC-wr8B3VlUochWVxP") }}：</span>
            <NuxtLink to="mailto:service@feee.io" target="_blank" class="decoration-underline">service@feee.io</NuxtLink>
          </li>
          <li>
            <span><i class="i-local:twtter text-[18px] mr-[10px]" />{{ $t("P5vQ5M3DaK_vUetztkJ11") }}</span>
            <NuxtLink to="https://x.com/Feeeio" target="_blank" >@Feeeio</NuxtLink>
          </li>
        </ul>
      </div>
    </AModal>
  </footer>
</template>

<style lang="scss" scoped>
.blue{
  color: var(--highlight);
}

.footer {
  background-color: var(--bg-one);

  > .main {
    --uno: 'page-section ';

    display: grid;
    grid-template: 'business-div support-div user-div contact-div other-div' auto 'copyright-div copyright-div copyright-div copyright-div copyright-div' auto / auto auto auto auto 1fr;
    gap: 40px 80px;
    align-items: flex-start;
    padding: 50px 20px;
    font-size: 16px;
    color: #989fae;

    @media bp-lt-tabletl {
      grid-template-areas:
        'business-div contact-div'
        'user-div support-div'
        'copyright-div copyright-div';
      grid-template-columns: auto auto;
      padding: 30px 20px;
    }

    @media bp-lt-mobilel {
      grid-template-areas:
        'business-div support-div '
        'copyright-div copyright-div ';
      grid-template-columns: auto;
      gap: 40px;
      padding: 22px 0;

      .contact-div, .user-div{
        display: none;
      }
    }
  }

  h3.title {
    --uno: 'lt-mobile:text-16px';

    margin-bottom: 20px;
    font-size: 20px;
    color: var(--first-text);
  }

  li {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: var(--secondary-text);

    a {
      line-height: 1.4em;
      color: inherit;

      &:hover {
        color: #266ef1;
      }
    }

    .icon {
      margin-right: 5px;
    }
  }

  .business-div {
    grid-area: business-div;
  }

  .support-div {
    grid-area: support-div;
  }

  .user-div {
    grid-area: user-div;
  }

  .contact-div {
    grid-area: contact-div;
  }

  .other-div {
    display: flex;
    flex-direction: column;
    place-self: flex-start flex-end;

    @media bp-lt-tabletl {
      display: none;
    }

    .brand {
      width: 160px;
      height: 45px;
      margin-bottom: 20px;
      background: url('@/assets/imgs/logo-name-s1.png') no-repeat 100% / contain;
    }

    .lang {
      display: inline-block;

      :deep(.lang-options) {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .copyright-div {
    grid-area: copyright-div;
    text-align: center;

    @media bp-lt-mobilel {
      text-align: left;
    }
  }
}
</style>
